var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-by-id"},[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('div',{staticClass:"comp-wrapper"},[(_vm.filter)?_c('div',[(_vm.filter)?_c('FilterForm',{key:JSON.stringify(_vm.filter),attrs:{"filter-list":_vm.filter,"own-submit":true,"is-loading":_vm.isLoading,"old-form-values":_vm.oldFilterValues,"has-reset-button":true},on:{"reset-search-fields":_vm.resetSearchFields,"filter-form-search":_vm.onFilterFormSearch}}):_vm._e()],1):_vm._e(),(_vm.type === 'table_view')?_c('div',[_c('AntdTable',{attrs:{"columns":_vm.columns,"rows":_vm.results},on:{"update-table":_vm.getModuleById}})],1):(_vm.type === 'paginated_table_view')?_c('div',[_c('AntdTable',{attrs:{"columns":_vm.columns,"rows":_vm.results && _vm.results.data,"table-title":_vm.results && _vm.results.tableTitle,"loading":_vm.isLoading,"expanded-row-query-data":_vm.expandedRowQueryData,"order-params":_vm.ordering,"page-count":Math.ceil(_vm.results
            && _vm.results.total / _vm.results.per_page),"total":_vm.results && _vm.results.total,"page-value":_vm.results && _vm.results.current_page,"per-page":_vm.results && _vm.results.per_page,"pagination":_vm.results && _vm.results.per_page
            && _vm.results.data.length >= _vm.results.per_page
            || (_vm.results && _vm.results.last_page_url)},on:{"on-page-change":_vm.getModuleById,"per-page-select-change":_vm.getModuleById,"search-by-order":_vm.searchByOrder,"update-table":_vm.getModuleById}},[(_vm.actions && _vm.actions[0])?_c('div',{staticClass:"button-wrapper"},_vm._l((_vm.actions),function(action,index){return _c('span',{key:index},[(action.action === 'sendWins')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleAction(action.action)}}},[_vm._v(" "+_vm._s(action.label)+" ")]):_vm._e()],1)}),0):_vm._e(),_c('div',{staticStyle:{"margin":"0 10px"}},[(_vm.download_token)?_c('DownLoadFile',{attrs:{"total-count":_vm.results && _vm.results.total,"down-load-url":_vm.downLoadUrl,"search-params":Object.assign({}, {orderBy: _vm.ordering && _vm.ordering.orderBy || 'ASC',
                            column: _vm.ordering && _vm.ordering.column || '',
                            reportName: _vm.$route.path,
                            perPage: 10000000,
                            token: _vm.download_token},
                            _vm.filterValue)}}):_vm._e()],1)])],1):_c('div',[_c('a-list',{attrs:{"bordered":"","data-source":_vm.columns},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('ViewItem',{attrs:{"translatable":item.translatable,"label":item.label,"type":item.type,"field-name":item.field,"translation":_vm.rows.translation,"content-value":_vm.rows[item.field]}})],1)}}])},[(!_vm.hideLanguageSwitcher)?_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('LanguageSwitcher')],1):_vm._e()])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }