<template>
  <a-layout id="components-layout-demo-responsive">
    <a-layout-sider
      v-model="collapsed"
      breakpoint="lg"
      :trigger="null"
      collapsible
      :collapsed-width="collapsedWidth"
      width="250"
      class="custom-layout-sider"
    >
      <SidebarComponent
        :menu-collapsed="collapsed"
        @collapse="collapseMenu"
      />
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0; text-align: left">
        <div>
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => collapseMenu()"
          />
        </div>
        <TabsLayout v-show="windowWidth > 740" />
        <div style="display: flex; align-items: center">
          <div class="user-tools">
            <div v-if="!switchedToBlot" class="bell-not-wrapper">
              <BellNotification />
            </div>
            <a-dropdown v-show="windowWidth > 500" class="user-info-wrapper">
              <a-avatar
                :src="userInfo.avatar"
                icon="user"
              />
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <router-link to="/change-password">
                    <a-icon type="user" /> Change Password
                  </router-link>
                </a-menu-item>
                <a-menu-item v-if="userInfo.need2FAAuth" key="2">
                  <router-link to="/google-authentication">
                    <a-icon type="google" />
                    <span>
                      Set Google Authentication
                    </span>
                  </router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <span class="user-name">{{ userInfo.name }}</span>
            <a-icon type="logout" @click="logout" />
          </div>
        </div>
      </a-layout-header>
      <div class="sub-header">
        <BreadCramp v-if="$route.meta.breadcrumb
          || $route.path === '/' || $route.path === '/dashboard'"
        />
        <div style="display: flex">
          <ClientGlobalSearch v-if="!hideClientSearch" />
          <PartnerChange v-if="!switchedToBlot" />
          <GlobalTimeZone v-if="!switchedToBlot" />
          <GlobalCurrencySelect v-if="!hideGlobalCurrency" />
        </div>
      </div>
      <a-layout-content
        :style="{ 'margin-bottom': '20px', minHeight: 'auto' }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
  <!--  <div-->
  <!--    class="wrapper"-->
  <!--    :class="{'sidebar-collapse': !isSidebarActive, 'sidebar-open': isSidebarActive}"-->
  <!--  >-->
  <!--    <HeaderComponent />-->
  <!--    <SidebarComponent />-->
  <!--    &lt;!&ndash;        <MenuLaunchPad&ndash;&gt;-->
  <!--    &lt;!&ndash;          v-if="isSidebarActive"&ndash;&gt;-->
  <!--    &lt;!&ndash;        />&ndash;&gt;-->
<!--      <TabsLayout />-->
<!--      <BreadCramp v-if="$route.meta.breadcrumb" />-->
  <!--    <div class="router-view-wrapper">-->
  <!--      <router-view />-->
  <!--    </div>-->
  <!--    <FooterComponent />-->
  <!--    <div-->
  <!--      v-if="isSidebarActive"-->
  <!--      id="sidebar-overlay"-->
  <!--      @click="closeSidebar"-->
  <!--    />-->
  <!--  </div>-->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import HeaderComponent from '../components/layout/HeaderComponent';
import SidebarComponent from '../components/layout/SidebarComponent';
import TabsLayout from './TabsLayout';
import PartnerChange from './PartnerChange';
// import FooterComponent from '../components/layout/FooterComponent';
import GlobalCurrencySelect from './GlobalCurrencySelect';
import BreadCramp from './BreadCramp';
import ClientGlobalSearch from './ClientGlobalSearch';
import BellNotification from './BellNotification';
import GlobalTimeZone from './GlobalTimeZone';
// import MenuLaunchPad from '../components/MenuLaunchPad/MenuLaunchPad';

export default {
  components: {
    // HeaderComponent,
    SidebarComponent,
    ClientGlobalSearch,
    TabsLayout,
    GlobalTimeZone,
    GlobalCurrencySelect,
    // FooterComponent,
    BreadCramp,
    PartnerChange,
    BellNotification,
    // MenuLaunchPad,
  },
  data() {
    return {
      collapsed: false,
      globalCurrency: 'AMD',
    };
  },
  computed: {
    ...mapGetters({
      isSidebarActive: 'sidebar/isActive',
      userInfo: 'info/userInfo',
    }),
    hasWithdrawBell() {
      return this.userInfo.hasWithdrawBell;
    },
    hideGlobalCurrency() {
      return process.env.VUE_APP_hideGlobalCurrency;
    },
    hideClientSearch() {
      return process.env.VUE_APP_hideClientSearch;
    },
    collapsedWidth() {
      return window.innerWidth < 1000 ? 0 : 45;
    },
    switchedToBlot() {
      return process.env.VUE_APP_BLOT_CONTROL === 'switch_blot';
    },
    windowWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    ...mapActions({
      closeSidebar: 'sidebar/close',
    }),
    async logout() {
      const success = await this.$store.dispatch('auth/logout');
      if (success) {
        await this.$store.dispatch('info/clearUserInfo');
        await this.$router.go('/login');
        // await this.$router.push('/login');
      }
    },

    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss">
.ant-layout-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px!important;
  .user-tools{
    padding-right: 20px;
    display: flex;
    align-items: center;
    .bell-not-wrapper{
      margin-right: 18px;
    }
    .user-info-wrapper{
      margin-right: 5px;
    }
    .user-name{
      margin-right: 5px;
    }
  }
}
.sub-header{
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 0 2px rgba(0, 21, 41, 0.12);
  border-bottom: 1px solid rgba(0, 21, 41, 0.12);
  padding-right: 24px;
}
.ant-layout-content{
  margin: 0;
}
.main-header, .home {
  margin-left: 0 !important;
}
.router-view-wrapper{
  min-height: calc(100vh - 193px);
}
.main-sidebar {
  z-index: 1038;
}
.menu-launch-pad {
  z-index: 1040;
}
#sidebar-overlay {
  display: block !important;
  background-color: rgba(#000, .3);
  transition: all .3s ease;
}
#components-layout-demo-responsive{
  min-height: 100vh;
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .trigger:hover {
    color: #1890ff;
  }
  .custom-layout-sider{
    text-align: left;
    background: #000929;
  }
}
</style>
